@charset "UTF-8";

/**
 *  Reset/Normalize
 * ------------------------------------------------------------------------- */
@import url('./normalize.css');
@import url('./reset.css');

input,
select,
textarea {
    border-radius: 0; /* Removes border radius applied by Safari */
}
/**
 *  Variables
 * ------------------------------------------------------------------------- */
:root {
    --colour__primary:              #8DB147;
    --colour__primary--dark:        #8DB147;
    --colour__secondary:            #DE7AAD;
    --colour__secondary--dark:      #BD5196;
    --colour__font:                 #636362;
    --colour__font-alt:             #FFF;
    --colour__grey:                 #E1E1E1;
    --colour__grey--dark:           #9D9C9C;
    --colour__grey--light:          #E6E6E6;
    --colour__white:                #FFF;
    --font:                         neo-sans, sans-serif;
    --transistion:                  400ms ease-in-out all;

    
    --colour__yellow: #F8D510;
}

/**
    Tabs
*/
.iprs-tabs {
    width: 100%; 
    clear: both; 
    float: left; 
    margin-bottom: 3em;
    margin-top: 3em;
}

.iprs-tab-list-item{
    display: contents;
}

.iprs-tab {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1em;
    margin: 1px 0;
    border: 0;
    color: var(--colour__white);
    background-color: var(--colour__secondary);
    font-size: 110%;
    font-weight: 500;
    text-decoration: none;
    text-align: center;
    line-height: 1;
    transition: 400ms ease-in-out all;
    transition: var(--transistion);
    cursor: pointer;
    float: left;
    margin-right: 2px;

    width: 9.5em;
    min-height: 8ex;
}
.iprs-tab:hover{
    background-color: var(--colour__primary);
}
.iprs-tab.iprs-tab-selected {
    background-color: var(--colour__primary--dark);
}

/**
    Rating stars
*/
.rating-stars {
    display: flex;
    justify-content: center;
    margin-top: 12px;
    margin-bottom: 55px;
}

.rating-star {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

    .rating-star.filled path {
        fill: var(--colour__yellow);
    }


.form__hidden__labels .form__label {
    display: none;
}

form table td {
    padding: 1em;
}

/*main .form__input.input-validate-highlight, .form__input.input-validate-highlight, .input-validate-highlight {
    border: solid 2px red;
    padding: 10px;
}*/
/**
* Appointment type
*/
body .IPRSCalEvent.fc-event {
    opacity: 1;
    overflow: hidden;
}


CalendarBlock {
    display: block;
    transition: opacity 0.3s ease-in-out;
}

.CalendarBlock.Available{
    opacity: 0.3;
}
.CalendarBlock.Available.RotaMode {
    opacity: 1;
}
.CalendarBlock.Available.InPast {
    opacity: 0.2;
}

.CalendarBlock.Confirmed, .CalendarBlockBg.Confirmed,
.CalendarBlock.Confirmed:hover {
    opacity: 1;
}
.CalendarBlock:hover, .CalendarBlock.Unconfirmed {
    opacity: .4;
}

.CalendarBlock:hover .CalendarBlockBg {
    transition: opacity 0.2s ease-out;
}
.CalendarBlockBg.InPast {
    opacity: 1;
    background-color: gray;
}
.CalendarBlockBg.Booking.InPast {
    opacity: 0.5;
}
.CalendarBlock:hover .CalendarBlockBg, .CalendarBlockBg.Unconfirmed {
    opacity: 0.3;
}

    .CalendarBlockTitle {
        display: block;
        
        margin: 0;
        padding: 0 0.5rem;
        fontSize: 0.7rem;
        color: red;
        
    }
.CalendarBlock h1.CalendarBlockTitle {
    font-size: 0.8rem;
    color: black;
    z-index: 1;
    position: relative;
    margin: 0;
}

.CalendarBlock .AppTypeList {
    display: block;
    flex-direction: column;
    flex-basis: auto;
    padding: 0;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
}

.CalendarBlock .AppTypeList.DiaryMode {
    /*opacity: 0.9;*/
}

.AppType {
        flex-grow: 1;
        justify-content: stretch;
        list-style: none;
        display: block;
        /*border: 1px dashed gray;*/
        padding: 0 0;
        overflow: hidden;
        position: relative;
}

.AppTypeInner {
    padding: 0 0.5em;
    overflow: hidden;
    max-height: 100%;
    color: black;
    z-index: 1;
    position: relative;
}

.CalendarBlockBg {
    background-color: rgb(141, 177, 71);
}

.CalendarBlockBg {
    position: absolute;
    display: block;
    height: 100%;
    min-height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 0;
}

.AppTypeBg{
    opacity: 0.4;
}
.selected .AppTypeBg {
    opacity: 1;
}

.AppTypeInner {
    opacity: 1;
    /*opacity: 0.3;*/
}

.selected .AppTypeInner{
    color: white;
    opacity: 1;
}

.CalendarBlock:hover .AppTypeInner, .AppTypeInner:hover {
    opacity: 1;
}

.Availability .CalendarBlock .AppType .AppTypeTitle {
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    color: black;
    margin: 0;
    padding: 0;
    font-size: 1rem;
}



    /**
*    Popover
* -----------------------------------------------------------------------------
*/

.IPRSPopover{
    
    position: relative;
}

.IPRSPopoverContent{

    position: absolute;
}

/**
 *  Background
 * ------------------------------------------------------------------------- */
    body {
        overflow-x: hidden;
    }

.background__solid {
    background: var(--colour__secondary);
}

.background__gradient {
    background: linear-gradient(var(--colour__secondary), var(--colour__secondary--dark)) no-repeat; 
}

.background__grey {
    background: var(--colour__grey);
}

/*
 *  Typography
 *------------------------------------------------------------------------- */
html {
    font-size: 14px;
}

body {
    font-weight: 400;
    font-family: var(--font);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}


.typography {
    color: var(--colour__font);
    font-size: 1rem;
    line-height: 1.5;
}

.typography p {
    margin-bottom: 1em;
}


.typography strong {
    font-weight: bold;
}


.typography em {
    font-style: italic;
    font-weight: bold;
}

.typography hr {
    display: block;
    width: 100%; height: 0;
    margin: 36px 0 24px;
    border: 0;
    border-bottom: solid 1px var(--colour__font);
    outline: 0;
}

.typography h1,
.typography .h1 {
    margin-bottom: 1em;
    color: var(--colour__font);
    font-weight: 500;
    font-size: 1.2rem;
}


@media only screen and (min-width: 600px) {
    .typography h1,
    .typography .h1 {
        font-size: 1.5rem;
    }
}

.typography h2,
.typography .h2 {
    margin-top: 1em;
    color: var(--colour__font);
    font-weight: 500;
    font-size: 1.2rem;
}

@media only screen and (min-width: 600px) {
    .typography h2,
    .typography .h2 {
        font-size: 1.4rem;
    }
}


.typography h3,
.typography .h3 {
    margin-bottom: 1em;
    color: var(--colour__secondary);
    font-weight: 500;
    font-size: 1.5rem;
}

@media only screen and (min-width: 600px) {
    .typography h3,
    .typography .h3 {
        font-size: 1.6rem;
    }
}



.typography ul {
    margin: 0 0 1em; padding: 0 0 0 1.5em;
    list-style-type: disc;
}


.typography ol {
    margin: 0 0 1em; padding: 0 0 0 1.5em;
    list-style-type: decimal;
}


.typography blockquote {
    margin: 0 0 1em;
    font-style: italic;
}

.typography blockquote p {
    margin: 0;
}

.typography cite {
    display: block;
    font-weight: bold;
    font-style: normal;
}

.typography sup,
.typography sub {
    position: relative;
    top: -0.4em;
    font-size: 80%;
    vertical-align: baseline;
}


.typography sub {
    top: 0.4em;
}


.typography u {
    text-decoration: none;
}


.typography a {
    color: inherit;
}

.typography a:link,
.typography a:visited {
     text-decoration: underline;
 }

.typography a:hover,
.typography a:active {
     text-decoration: none;
}

.typography .center {
    text-align: center;
}

ul.unstyled{
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.unstyled li{
    display: block;
    clear: both;
}

label {
    display: block;
    clear: left;
}
/**
 *  Container
 * ------------------------------------------------------------------------- */
.container {
    /*max-width: 1235px; */
    width: calc(100% - 75px);
    margin: 0 auto;
    padding: 0;
/*    float: none;
    clear: both;
    display: block;*/
}

.container-sm {
    width: calc(100% - 100px);
}

@media (min-width: 1024px){
    .container-sm {
        width: calc(100% - 500px);
    }
}

/**
 *  Header
 * ------------------------------------------------------------------------- */
.header {
    color: var(--colour__font-alt);
    line-height: 1.1;
}

@media only screen and (min-width: 750px) {
    .header {
        display: flex;
        justify-content: space-between;
    }
}

.header__date {
    font-size: 13px;
    margin-top: 20px;
    margin-bottom: 19px;
    display: block;
}

@media only screen and (min-width: 750px) {
    .header__date {
        width: 208px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.header__time {
    margin-left: 17px;
    display: inline-block;
}

@media only screen and (min-width: 750px) {
    .header__time {
        margin-left: auto;
    }
}

.header__user-account {
    margin-bottom: 19px;
    font-size: 12px;
}

@media only screen and (min-width: 750px) {
    .header__user-account {
        min-width: 239px;
        display: flex;
        align-items: center;
        padding: 19px 25px 21px;
        background: rgba(255, 255, 255, .25);
        margin-bottom: 0;
        transition: var(--transistion);
    }
}

.header__user-account:hover {
    background: rgba(255, 255, 255, .50);

}

.header__user-account-avatar {
    margin-right: 21px;
    display: none;
}

@media only screen and (min-width: 750px) {
    .header__user-account-avatar {
        display: block;
    }
}

.header__user-account-triangle {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 8px solid var(--colour__white);
    margin-left: 15px;
    margin-top: 2px;
    display: none;
}

@media only screen and (min-width: 750px) {
    .header__user-account-triangle {
        display: block;
    }
}

.header__user-username {
    font-weight: 500;
    display: inline-block;
}

/**
 *  Footer
 * ------------------------------------------------------------------------- */
.footer {
    padding: 28px 0;
}

p.footer__content {
    color: var(--colour__white);
    font-size: 12px;
    margin-bottom: 0;
    text-align: center;
}

p.footer__content a {
    display: block;
    margin: 1em 0;
}

@media only screen and (min-width: 750px) {
    p.footer__content {
        text-align: left;
    }

    p.footer__content a {
        display: inline;
        margin: 0;
    }
}

.footer__delimiter {
    display: none;
}

@media only screen and (min-width: 750px) {
    .footer__delimiter {
        display: inline-block;
        margin: 0 20px;
    }
}

/**
*  Left Nav
*---------------------------------------------------------------------------
*/

.leftNav{
    
    color: black;
    /*background-color: var(--colour__primary);*/
    /*flex: 1;*/
    /*float: left;*/ 
    min-width: 21em;
    padding: 0;
    padding-left: 1em;
    padding-top: 45px;
    margin-right: 5px;
}

.leftNav ul {
    list-style: none;
    padding: 0;
    display: block;
    width: 100%;
}

.leftNav ul {
    display: block;
    width: 100%;
}

.leftNav ul a:link {
    text-decoration: none;
}

.leftNav h2{
    color: var(--colour__secondary);
    margin-bottom: 0;
    margin-top: .3em;
    margin-bottom: .2em;
}
.leftNav h2:first-child{
    margin-top: 0;
}
.leftNav ul {
    margin-bottom: 1vh;
}

.scroll-list {
        height: 10em;
        overflow-y: scroll;
    }

.sideMenuLink {
    border-top:     1px solid white;
    border-bottom:  1px solid white;
    margin: 0;
    padding: 0;
    display: flex;
    line-height: 1.2;
    /*clear: both;*/
    /*float: left;*/
    width: 100%;
    /*min-width: 20em;*/
}
.sideMenuLink button {
    flex: auto;
    padding: 0;
    margin-right: 2px;
    background-color: rgba(0,0,0,0);
}
h3.sideMenuLink-anchor {
    color: inherit;
    font-size: inherit;
    font-weight: bold;
    padding: 0;
    margin: 0;
}
.sideMenuLink-anchor, h3.sideMenuLink-anchor {
    
    flex: auto;
    display: block;
    /*vertical-align: bottom;*/
    box-sizing: border-box;
    /*padding-right: 1em;*/
    min-width: 10em;
    background-color: inherit;
    padding: 4px 0;
    padding-left: 2px;
    
    line-height:    1.2;
}
.sideMenuLink-edit{
    flex: auto;
    display: block;
    min-width: 15em;
}
.sideMenuLink a.fw {
    width: 100%;
}
.sideMenuLink.active .sideMenuLink-anchor{
    border-color: var(--colour__primary);
    background-color: var(--colour__grey--light);
}
.sideMenuLink:hover .sideMenuLink-anchor{
    background-color: var(--colour__grey--light);
}
.sideMenuLink button:hover {
    background-color: var(--colour__secondary);
}

.sideMenuButtons{
    max-height: 1em;
}

/**
 *  Main
 * ------------------------------------------------------------------------- */

.login_container{
    position: relative;
    height: calc(100vh - 20em);
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginOverlay{
/*    position: absolute;
    display: block;
    top: 50%;
    left: calc( 50% - 10em );*/
    background-color: white;
    padding: 2em;
    border: 3px solid var(--colour__primary);
    min-width: 20em;
}

.loginOverlay legend{
    max-width: 15em;
    margin-bottom: 1em;
    font-style: italic;
}

.loginOverlay label{
    font-weight: bold;
}

main {
    font-size: 100%;
}

h1, h2, h3{
    clear: left;
}

h1.calHead {
    color: var(--colour__secondary);
}
main h1, .typography main h1 {
    font-size: 110%;
    font-weight: bold;
    color: var(--colour__secondary--dark);
    margin-bottom: 1em;
}

main h2, .typography main h2 {
    color: var(--colour__primary);
}

main p {
    clear: left;
}

/*main select {
    padding: 2px 0;
    display: block;
    margin: 4px 0;
}*/

/**
 *  Main
 * ------------------------------------------------------------------------- */
.main_container{
    display: flex;
    align-items: stretch;
    background: var(--colour__white);
}
.main {
    
    flex: auto;
    /*float: left;*/
    /*position: relative;*/
}

.main__top {
    padding: 4px 0 10px;
    text-align: center;
}

@media only screen and (min-width: 850px) {
    .main__top {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }
}

.main__top-logo {
    margin: 10px 29px;
    width: 154px;
    display: inline-block;
}

h1.main__top-title {
    margin-bottom: 12px;
}

.main__section {
    width: 100%;
    max-width: 640px; 
    margin: 0 auto;
    padding: 24px 0;
}

@media only screen and (min-width: 685px) {
    .main__section {
        padding: 41px 20px;
    }
}

@media only screen and (min-width: 550px) {
    .main__bottom {
        display: flex;
        border-top: 1px solid var(--colour__font);
        padding: 30px 0 10px;
    }
}

.main__bottom-column {
    text-align: center;
    font-size: 15px;
}

.main__bottom-column:first-child {
    padding-bottom: 16px;
    border-bottom: 1px solid var(--colour__font);
    margin-bottom: 16px;
}

@media only screen and (min-width: 550px) {
    .main__bottom-column {
        width: 50%;
        padding: 0 12px;
        font-size: 16px;
    }

    .main__bottom-column:first-child {
        padding-bottom: 0;
        border-bottom: none;
        margin-bottom: 0;
    }
}

/**
 *  Progress bar
 * ------------------------------------------------------------------------- */
ol.progress__stages {
    width: 100%;
    margin: 0;
    padding: 11px 0;
    display: none;
    align-items: center;
    justify-content: center;
    min-height: 42px;
}

@media only screen and (min-width: 685px) {
    ol.progress__stages {
        display: flex;
    }
}

.progress__stage {
    margin: 0 18px;
}

.progress__stage--active {
    color: var(--colour__primary);
    font-weight: 500;
}

.progress__bar {
    background: var(--colour__primary);
    height: 4px;
    display: none;
}

@media only screen and (min-width: 685px) {
    .progress__bar {
        display: block;
    }
}

.progress__bar--20 {
    width: 20%;
}

.progress__bar--40 {
    width: 40%;
}

.progress__bar--60 {
    width: 60%;
}

.progress__bar--80 {
    width: 80%;
}

.progress__bar--100 {
    width: 100%;
}

.progress__bar-step {
    background-color: var(--colour__primary);
    color: var(--colour__white);
    padding: 9px 0 7px;
    margin-bottom: 0 !important;
}

.progress__bar-step.progress__bar-step--final {
    background-color: var(--colour__grey--dark);
}

@media only screen and (min-width: 685px) {
    .progress__bar-step {
        display: none;
    }
}

/**
 *  Appointment types
 * ------------------------------------------------------------------------- */
.appointment-types {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
}

/*@media only screen and (min-width: 460px) {
    .appointment-types {
        padding: 16px 0;
        display: block;
        margin-bottom: 0;
    }
}*/

.appointment-types__type {
    width: 100%;
    max-width: calc(50% - 5px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 13px 5px;
    text-align: center;
    margin-bottom: 10px;
    background: var(--colour__grey);
    cursor: pointer;
}

@media only screen and (min-width: 460px) {
    .appointment-types__type {
        max-width: none;
        margin-bottom: 14px;
        flex-direction: row;
        padding: 18px 46px 18px 19px;
        text-align: left;
    }
}

.appointment-types__type-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.appointment-types__type-image-wrapper {
    width: 58px; height: 58px;
    background: var(--colour__white);
    overflow: hidden;
    border-radius: 50%;
    flex-shrink: 0;
    position: relative;
    margin-bottom: 16px;
}

@media only screen and (min-width: 460px) {
    .appointment-types__type-image-wrapper {
        margin-right: 28px;
        margin-bottom: 0;
    }
}

.appointment-types__type-svg-fill {
    /*fill: var(--colour__primary);*/
}

.appointment-types__type-svg-stroke {
    /*stroke: var(--colour__primary);*/
}

.appointment-types__type-content {
    margin-right: auto;
}

.appointment-types__type-content-title,
.appointment-types__type-content-text {
    display: block;
}

.appointment-types__type-content-title {
    margin-bottom: 6px !important;
}

.appointment-types__type-content-title.appointment-types__type-content-title--alt {
    color: var(--colour__secondary);
}

.appointment-types__type-checkbox {
    display: none;
}

@media only screen and (min-width: 460px) {
    .appointment-types__type-checkbox {
        width: 40px;
        height: 40px;
        display: block;
        opacity: 0;
        background-color: var(--colour__primary);
        margin: auto 0 auto 24px;
        position: relative;
        transition: var(--transistion);
        flex-shrink: 0;
    }
}

.appointment-types__type-checked,
.appointment-types__type-svg {
    height: auto;
    width: 2em;
    margin-right: 1em;
    margin-top: 1em;
/*    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);*/
}

@media only screen and (min-width: 460px) {
    .appointment-types__type-input:checked ~ .appointment-types__type-checkbox {
        opacity: 1;
    }
}

/**
 *  Button Holder
 * ------------------------------------------------------------------------- */
.button-holder {
    text-align: center;
    padding-bottom: 10px;
}

.button-holder__go-back {
    display: block;
    margin-bottom: 23px;
}

.button-holder__go-back.button-holder__go-back--alt {
    color: var(--colour__secondary);
}

.wrapper{
    display: inline-block;
    width: 100%;
/*    float: left;
    clear: both;*/
    margin-bottom: 1em;
    margin-top: 1em;
}

.fBlock {
    display: inline-block;
    float: left;
    
    margin-right: 1em;
}

/**
*
*/

.cancelLink, .typography .cancelLink {
    margin-top: 1em;
    font-size: 18px;
    font-weight: bold;
    color: var(--colour__secondary);
}


/**
 *  Button
 * ------------------------------------------------------------------------- */


button[type='image']{
    font-size: 0;
}

main dl {
    display: inline-block;
    margin-bottom: 1em;
    min-width: 30em;
    float: left;
    clear: left;
}

main dt {
    width: 10em;
    float: left;
    font-weight: bold;
    clear: left;
}

main dd {
    float: left;
}

main .float {
    display: block; 
    float: left;
    margin-right: 1em;
}

main  ul.stdList{
    list-style: none;
/*    display: block;
    float: left;
    clear: left;
    width: 100%;*/
}

main  ul.stdList li {
    display: flex;
    float: left;
    clear: left;
    width: 100%;
}

.listLabel {
/*    display: block;
    width: 5em;
    float: left;*/
}

main .imgBtn {
/*    padding: 0;
    float: left;
    
    border:0; 
    background-color: 'white';
    padding: '5px 0';*/
}

main .imgBtn svg{
    /*background-color: inherit;*/
}

.button, main button{
    display: inline-block;
    padding: 8px 15px;
    margin: 0 auto 24px;
    border: 0;
    color: var(--colour__white) !important;
    background-color: var(--colour__secondary);
    font-size: 18px;
    font-weight: 500;
    text-decoration: none !important;
    text-align: center;
    vertical-align: center;
    line-height: 1;
    transition: var(--transistion);
    cursor: pointer;
    /*float: left;*/
    margin-right: 1em;
}

.singleBtn {
    clear: left;
}

.button:last-child, main button:last-child {
    margin-right: 0;
    clear: right;
}

.button:hover {
    opacity: 0.65;
}

.button--extra-wide {
    max-width: 324px;
    width: 100%;
}

.button--inline {
    margin: 0 8px 12px;
    padding: 11px 36px;
}

@media only screen and (min-width: 675px) {
    .button--inline {
        margin: 0 8px 24px;
    }
}

.button--postcode {
    font-size: 15px;
    height: 30px;
    width: 100%;
    padding: 6px 24px;
    margin: 10px auto 0;
}

@media only screen and (min-width: 675px) {
    .button--postcode {
        max-width: 191px;
        margin: 0 auto 0 27px;
    }
}

/**
 *  Modal
 * ------------------------------------------------------------------------- */
.modal__outer {
    width: 100%; height: 100%;
    position: absolute;
    top: 0; left: 0;
    padding: 10px;
    background-color: rgba(255, 255, 255, .5);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal__inner {
    max-width: 512px;
    width: 100%;
    border: 3px solid var(--colour__primary);
    background-color: var(--colour__white);
    padding: 33px 38px 0;
    text-align: center;
}

@media only screen and (min-width: 600px) {
    .modal__inner {
        margin-top: 165px;
    }
}

.modal__info {
    height: 16px; width: 16px;
    display: inline-block;
    color: var(--colour__secondary) !important;
    border: 1px solid var(--colour__grey--dark);
    text-align: center;
    font-size: 10px !important;
    text-decoration: none !important;
    border-radius: 50%;
    margin-left: 2px;
}

/**
 *  Time select
 * ------------------------------------------------------------------------- */
.time-select__top-bar {
    background-color: var(--colour__grey);
    padding: 15px 23px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 34px;
}

.time-select__filter-label {
    font-weight: 500;
    margin-right: 13px;
}

.time-select__button {
    height: 30px;
    display: inline-block;
    padding: 0 11px;
    margin: 0 5px 0;
    border: 1px solid var(--colour__font);
    background: transparent;
    color: inherit;
    font-weight: 500;
    text-decoration: none !important;
    text-align: center;
    vertical-align: center;
    line-height: 30px;
    transition: var(--transistion);
    cursor: pointer;
}

.time-select__button.time-select__button--desktop {
    display: none;
}

@media only screen and (min-width: 550px) {
    .time-select__button.time-select__button--desktop {
        display: inline-block;
    }
}

.time-select__button--wider {
    padding: 0 23px;
}

.time-select__button--larger-font {
    font-size: 18px;
}

.time-select__button--active,
.time-select__button:hover {
    border: 1px solid var(--colour__secondary--dark);
    background: var(--colour__secondary--dark);
    color: var(--colour__white);
}

.time-select__dropdown {
    display: inline-block;
    border: 1px solid var(--colour__font);
    width: 100px !important;
    text-align: left;
}

@media only screen and (min-width: 550px) {
    .time-select__dropdown {
        display: none;
    }
}

.time-select__dates {
    width: 100%;
    max-width: 586px;
    margin: 0 auto 49px;
    display: flex;
}

.time-select__date {
    width: calc(20% - 20px);
    margin: 0 10px;
}


h3.time-select__date-title {
    color: var(--colour__font);
    text-align: center;
    font-size: 13px;
    margin-bottom: 19px;
    line-height: 1.3;
}

.time-select__date-day {
    display: block;
}

@media only screen and (min-width: 650px) {
    .time-select__date-day {
        display: inline;
    }
}

.time-select__time {
    display: block;
    margin-bottom: 15px;
    cursor: pointer;
}

.time-select__time-meridiem {
    display: block;
}

@media only screen and (min-width: 650px) {
    .time-select__time-meridiem {
        display: inline;
    }
}

.time-select__time-content {
    display: block;
    text-align: center;
    border: 1px solid var(--colour__secondary--dark);
    color: var(--colour__secondary--dark);
    font-weight: 500;
    padding: 8px 0;
    transition: var(--transistion);
    line-height: 1.1;
}

@media only screen and (min-width: 650px) {
    .time-select__time-content {
        padding: 14px 0;
    }
}

.time-select__time-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.time-select__time-input:checked ~ .time-select__time-content,
.time-select__time:hover .time-select__time-content {
    background-color: var(--colour__secondary--dark);
    color: var(--colour__white);
}

/**
 *  Form
 * ------------------------------------------------------------------------- */
.main .form, .main form {
    max-width: 598px;
    margin: 0;
    text-align: left;
}

@media only screen and (min-width: 675px) {
    .form {
        text-align: left;
    }
}

.form__bottom {
    margin-bottom: 40px;
}

.form__field {
    margin-bottom: 16px;
}

@media only screen and (min-width: 675px) {
    .form__field {
        display: flex;
        align-items: center;
    }
}

.form__date-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form__date-wrapper {

}

@media only screen and (min-width: 675px) {
    .form__date-wrapper {
        justify-content: flex-start;
    }
}

.form__label, main form label{
    font-weight: bold;
    flex-shrink: 0;
    display: block;
    margin: 7px 16px;
    /*margin-bottom: 7px;*/
   
    /*margin-left: 16px;*/
}

.main form h1, .main form h2,
.main form h3{
    color: var(--colour__font);
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 2px;
}

.main form legend{
    font-weight: bold;
}

@media only screen and (min-width: 675px) {
    .form__label, main form label {
        width: 146px;
        margin-bottom: unset;
        margin-left: 0;
    }
}

.form__label.form__label--auto-width {
    width: auto;
}

.form__label.form__label--margin-x {
    margin-right: 12px;
    margin-left: 12px;
}

input.form__input.input-validate-highlight, select.form__dropdown.input-validate-highlight, input.input-validate-highlight {
    border-color: red;
    border-width: 1px;
    border-style: solid;
    outline: 0;
}

.form__input,
.form__dropdown,
.form__textarea,
main form input {
    height: 30px;
    color: var(--colour__font);
    background: var(--colour__grey);
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    width: 100%;
    padding-left: 11px;
    padding-right: 11px;
    /*margin-bottom: 11px;*/
}

/*.form__dropdown,
main form select {
    background: transparent;
    position: relative;
    z-index: 2;
    text-align: left;

    //padding: 2px 0;
    display: block;
    //margin: 4px 0;
}*/

.form__dropdown::-ms-expand {
    display: none;
}

.form__dropdown-wrapper {
    background: var(--colour__grey);
    width: 100%;
    position: relative;
}

@media only screen and (min-width: 675px) {
    .form__dropdown-wrapper.form__dropdown-wrapper--width-100 {
        width: 100px;
    }

    .form__dropdown-wrapper.form__dropdown-wrapper--width-280 {
        width: 280px;
    }
}

.form__dropdown-wrapper:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 9px solid var(--colour__secondary);
    position: absolute;
    top: 50%; right: 11px;
    transform: translateY(-50%);
}

.form__input.form__input--2-chars {
    width: 40px;
    text-align: center;
}

.form__input.form__input--4-chars {
    width: 66px;
    text-align: center;
}

@media only screen and (min-width: 675px) {
    .form__input.form__input--2-chars {
        margin-right: 22px;
    }
}

@media only screen and (min-width: 675px) {
    .form__input.form__input--set-width {
        max-width: 217px;
        width: 100%;
    }
}

.form__input--mobile-y-bottom {
    margin-bottom: 16px;
}

@media only screen and (min-width: 675px) {
    .form__input--mobile-y-bottom {
        margin-bottom: 0;
    }
}

.form__textarea {
    height: 100px;
    padding: 11px;
    resize: none;
    overflow-y: scroll;
}

.form__description {
    display: block;
    margin: -4px 0 18px !important;
}

@media only screen and (min-width: 675px) {
    .form__description {
        margin: -4px 0 18px 160px !important;
    }
}

.form__mock-dropdown {
    display: block;
    vertical-align: center;
    padding-top: 6px;
    width: 100%; height: 30px;
    position: relative;
}

.form__mock-dropdown-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.form__mock-dropdown-options {
    display: block;
    position: absolute;
    top: 100%; left: 0;
    border: 1px solid var(--colour__grey);
    max-height: 0;
    transition: var(--transistion);
    overflow: hidden;
    width: 100%;
}

@media only screen and (min-width: 675px) {
    .form__mock-dropdown-options {
        width: 100px;
    }
}

.form__mock-dropdown-option {
    display: block;
    padding: 8px 11px;
    background-color: var(--colour__grey--light);
    border-top: 1px solid var(--colour__grey);
    width: 100%;
}

.form__mock-dropdown-input:checked ~ .form__mock-dropdown-options {
    max-height: 300px;
}

.form__mock-dropdown-methods {
    display: block;
    position: absolute;
    top: 100%; left: 0;
    border: 1px solid var(--colour__grey);
    max-height: 0;
    transition: var(--transistion);
    overflow: hidden;
    width: 100%;
}

@media only screen and (min-width: 675px) {
    .form__mock-dropdown-methods {
        width: 280px;
    }
}

.form__mock-dropdown-method {
    display: flex;
    align-items: center;
    padding: 8px 11px;
    background-color: var(--colour__grey--light);
    width: 100%;
    border-top: 1px solid var(--colour__grey);
}

.form__mock-dropdown-input:checked ~ .form__mock-dropdown-methods {
    max-height: 300px;
}

.form__radio {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
}

.form__radio .form__radio-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.form__radio-custom {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: var(--colour__white);
    border-radius: 50%;
    margin-left: 10px;
}

.form__radio:hover .form__radio-input ~ .form__radio-custom {
    background-color: rgba(255, 255, 255, 0.8);
}

.form__radio .form__radio-input:checked ~ .form__radio-custom {
    background-color: var(--colour__white);
}

.form__radio .form__radio-input:checked ~ .form__radio-custom:after {
    display: block;
}

.form__radio .form__radio-custom:after {
    content: "";
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: var(--colour__secondary);
}

.form__checkbox {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    margin-right: 5px;
}

/* Hide the browser's default checkbox */
.form__checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.form__checkbox-custom {
    position: absolute;
    top: 0;
    left: 5px;
    height: 20px;
    width: 20px;
    background-color: var(--colour__white);
}

/* When the checkbox is checked, add a blue background */
.form__checkbox .form__checkbox-input:checked ~ .form__checkbox-custom {
    background-color: var(--colour__white);
}

/* Create the checkmark/indicator (hidden when not checked) */
.form__checkbox-custom:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.form__checkbox .form__checkbox-input:checked ~ .form__checkbox-custom:after {
    display: block;
}

/* Style the checkmark/indicator */
.form__checkbox .form__checkbox-custom:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid var(--colour__secondary);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/**
 *  Content boxes
 * ------------------------------------------------------------------------- */
.content-boxes {
    padding: 16px 0 16px;
}

.content-boxes__box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
    margin-bottom: 14px;
    background: var(--colour__grey);
    cursor: pointer;
}

@media only screen and (min-width: 675px) {
    .content-boxes__box {
        width: 100%;
        padding: 18px;
        flex-direction: row;
        align-items: flex-start;
        text-align: left;
    }
}

.content-boxes__box.content-boxes__box--max-494 {
    max-width: 494px;
    margin: 0 auto 14px;
}

.content-boxes__box-image-wrapper {
    width: 70px; height: 70px;
    background: var(--colour__white);
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 18px;
    flex-shrink: 0;
    position: relative;
}

@media only screen and (min-width: 675px) {
    .content-boxes__box-image-wrapper {
        margin-right: 28px;
    }
}

.content-boxes__box-svg-fill {
    fill: var(--colour__primary);
}

.content-boxes__box-content-title,
.content-boxes__box-content-text {
    display: block;
}

.content-boxes__box-content-title {
    margin-bottom: 6px !important;
    color: var(--colour__secondary) !important;
}

.content-boxes__box-content-text {
    margin-bottom: 0 !important;
    width: 100%;
}

.content-boxes__box-svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.content-boxes__link  {
    color: var(--colour__secondary) !important;
    text-decoration: none !important;
}

.content-boxes__link:hover  {
    text-decoration: underline !important;
}

.content__app-store-link {
    margin: 0 7px 24px;
    display: inline-block;
}

.content-boxes__optionset {
    display: block;
    margin-top: 8px;
}

@media only screen and (min-width: 675px) {
    .content-boxes__optionset {
        display: inline;
    }
}


/* From old CSS */




.topHeader{
    background-color: var(--colour__grey--light);
    /*background-color: var(--colour__white); */
    
    /* margin-bottom: 1em; */
    color: var(--colour__font);
    font-weight: 500;
    font-size: 1.8rem;
}

/*.menu{
    background-color: var(--colour__primary);
    
    color:            var(--colour__black);
    
    height: 5em;
    
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    
    padding: 10px 29px;
    text-align: center;
}*/

.header__user-account{
    
    background-color: var(--colour__grey--light);
}

.main__top-title{
    margin-top: 10px;
}
